<template lang="pug">
.vc-admin-promoter-media-accounts-selector
  b-field(
    label="社群網站帳號"
    :type="errors.errorClassAt('media_accounts')"
    :message="errors.get('media_accounts')"
    custom-class="required title"
  )

  .group(v-for="account in availableMediaAccounts")
    .group-title
      b-field(:label="'媒體平台'")
        b-select(
          v-model="account.media_service_id"
          data-behavior="promoter-media_service_id"
          expanded
        )
          option(
            v-for="mediaService in availableMediaServicesWithSelf(account)"
            :value="mediaService.id"
          )
            | {{ mediaService.name }}

    .group-value
      b-field(
        :label="attributeLocaleText('promoter/media_account', 'username')"
      )
        b-input(
          type="text"
          :placeholder="'輸入帳號'"
          v-model="account.username"
        )

    .group-value
      b-field(
        :label="attributeLocaleText('promoter/media_account', 'fans_count')"
      )
        b-input(
          type="text"
          :placeholder="'輸入粉絲數'"
          v-model="account.fans_count"
        )

    //- .group-value.link
    //-   b-field(
    //-     :label="attributeLocaleText('promoter/media_account', 'link_url')"
    //-   )
    //-     a.button(
    //-       v-if="account.link_url"
    //-       :href="account.link_url"
    //-       target="_blank"
    //-     )
    //-       span 連結

    .group-option
      a(@click="deleteMediaAccount(account)")
        .icon
          i.fa.fa-close
  .group-extra
    b-button(
      :label="'+ 新增社群網路'"
      @click="addMediaAccount"
    )
</template>
<script>
import { computed, defineComponent, onMounted, reactive, watch } from 'vue'
import { useStore } from 'skid-composables'

export default defineComponent({
  props: {
    mediaAccounts: {
      type: Array,
      required: true
    },

    errors: {
      type: Object,
      required: true
    }
  },

  setup(props, { emit }) {
    const store = useStore()

    const mediaServices = computed(() => {
      return store.getters['promoterMediaServices/all']
    })

    const availableMediaServices = computed(() => {
      return mediaServices.value.filter((service) => {
        const selectedServiceIds = props.mediaAccounts.map((account) => {
          return account.media_service_id
        })

        return !selectedServiceIds.includes(parseInt(service.id))
      })
    })

    onMounted(async () => {
      await store.dispatch('promoterMediaServices/all')
    })

    const data = reactive({
      mediaAccounts: props.mediaAccounts
    })

    const availableMediaAccounts = computed(() => {
      return data.mediaAccounts.filter((account) => {
        return account.remove != 1
      })
    })

    const addMediaAccount = () => {
      data.mediaAccounts.push({
        media_service_id: null,
        username: '',
        fans_count: null
      })

      emit('update:mediaAccounts', data.mediaAccounts)
    }

    const deleteMediaAccount = (account) => {
      data.mediaAccounts = props.mediaAccounts.filter((acc) => {
        if (account.id && account.id === acc.id) {
          acc.remove = 1
          return true
        }

        if (
          !account.id &&
          !acc.id &&
          account.media_service_id === acc.media_service_id
        )
          return false

        return true
      })

      emit('update:mediaAccounts', data.mediaAccounts)
    }

    const availableMediaServicesWithSelf = (account) => {
      if (!account) return availableMediaServices.value

      const accountService = store.getters['promoterMediaServices/find'](
        account.media_service_id
      )

      return [accountService].concat(availableMediaServices.value)
    }

    return {
      availableMediaAccounts,
      addMediaAccount,
      deleteMediaAccount,
      mediaServices,
      availableMediaServices,
      availableMediaServicesWithSelf
    }
  }
})
</script>
